import React, { Suspense, lazy } from "react";
import "./App.css";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga4";

import Loader from "./Util/Loader/Loader";

const Blogs = lazy(() => import(/* webpackChunkName: "Blogs"*/ "./Blogs/Blog"));

const InvestmentCharter = lazy(() =>
  import(/* webpackChunkName: "InvestmentCharter"*/ "./Investment/Investment")
);

const CourseDetails = lazy(() =>
  import(/* webpackChunkName: "CourseDetails"*/ "./CourseDetail/CourseDetails")
);

// import Error from "./Util/Error/Error";
const About = lazy(() =>
  import(/* webpackChunkName: "About"*/ "./About/About")
);

const Mentorship = lazy(() =>
  import(/* webpackChunkName: "Mentorship"*/ "./Mentorship/Mentorship")
);

// const Performance = lazy(() =>
//   import(/* webpackChunkName: "Performance"*/ "./Performance/Performance")
// );

const Home = lazy(() => import(/* webpackChunkName: "Home"*/ "./Home/Home"));

const Event = lazy(() =>
  import(/* webpackChunkName: "Event"*/ "./Events/Event")
);
const Disclaimer = lazy(() =>
  import(/* webpackChunkName: "Disclaimer"*/ "./Disclaimer/Disclaimer")
);
const Courses = lazy(() =>
  import(/* webpackChunkName: "Courses"*/ "./Courses/Courses")
);
const ContactUs = lazy(() =>
  import(/* webpackChunkName: "Contact"*/ "./Contact/Contactus")
);

const MasterClass = lazy(() =>
  import(/* webpackChunkName: "Contact"*/ "./MasterClass/MasterClass")
);

const theme = createTheme({
  palette: {
    primary: {
      main: "#0F2D49",
    },
    secondary: {
      main: "#F1DEDF",
    },
  },
});

//
// GTag measurement ID

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.customHistory = createBrowserHistory();
  }

  componentDidMount() {
    ReactGA.initialize("G-6W2D8VSEYP");
  }

  render = () => {
    return (
      <ThemeProvider theme={theme}>
        <Router history={this.customHistory}>
          <Suspense
            fallback={
              <div className="loader-container">
                <Loader />
              </div>
            }
          >
            <Switch>
              <Route exact path="/home" component={Home} />
              <Route path="/about" component={About} />
              <Route path="/contact" component={ContactUs} />
              <Route exact path="/course" component={Courses} />
              <Route path="/events" component={Event} />
              <Route path="/mentorship" component={Mentorship} />

              <Route path="/disclaimer" component={Disclaimer} />
              <Route path="/investment" component={InvestmentCharter} />
              <Route path="/blogs" component={Blogs} />
              <Route exact path="/course/:id" component={CourseDetails} />
              <Route exact path="/masterclass" component={MasterClass} />
              {/* 404 Route */}
              <Route component={Home} />
            </Switch>
          </Suspense>
        </Router>
      </ThemeProvider>
    );
  };
}

export default App;
